<template>
  <div class="foot">
    <div class="cont">
      Copyright (C) 2022 Customaid:LLC All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 100%;
  background: #1d1d1f;
  .cont {
    line-height: 50px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
  }
}
</style>
